"use client";

import classNames from "classnames/bind";
import combineClassNames from "classnames";
import styles from "./navigation-dots.module.css";
import { useEffect, useState } from "react";
import { useTranslationsContext } from "../../helpers/TranslationsContext";
import template from "../../helpers/template";

const cx = classNames.bind(styles);

const MAX_DOTS_TO_SHOW = 4; // 0 indexed, eg 4 = 5 dots
const DOT_SIZE_PX = 16; // Unitless, size will be in px
const DOT_GAP_PX = 16; // Unitless, size will be in px
const DOTS_FROM_END = 1; // How many navigation dots from the end before scrolling, usually 1 or 2.

export const NavigationDots = ({
  dotsCount,
  activeIndex,
  onClickDot,
  dotLabelRenderFn,
  className,
}: {
  dotsCount: number;
  activeIndex: number;
  onClickDot: (index: number) => void;
  dotLabelRenderFn: (index: number) => React.ReactNode;
  className?: string;
}) => {
  const translations = useTranslationsContext();
  const dotFirst = 0; // 0 index of first Dot
  const dotLast = dotsCount - 1; // 0 index of last dot
  const hasDotTransform = dotsCount > MAX_DOTS_TO_SHOW + 1; // Do we have enough dots to need to animate them?
  const transformMultiplier = (DOT_SIZE_PX + DOT_GAP_PX) * -1; // The negative distance one Dot occupies

  const [dotVisibleRegionStart, setDotVisibleRegionStart] =
    useState<number>(dotFirst);
  const [dotVisibleRegionEnd, setDotVisibleRegionEnd] =
    useState<number>(MAX_DOTS_TO_SHOW);
  const [dotTransform, setDotTransform] = useState<number>(0);

  // There might be a more efficient way to do this, this required some mental gymnastics to get working.
  // I left the console logs as comments, to document each statement and make bug fixing a bit easier.
  useEffect(() => {
    // Do we have enough dots so we need to animate them? (usually yes)
    if (hasDotTransform) {
      if (activeIndex <= dotFirst) {
        // console.log("Right, Looping around");
        setDotTransform(dotFirst);
        setDotVisibleRegionStart(dotFirst);
        setDotVisibleRegionEnd(MAX_DOTS_TO_SHOW);
      } else if (activeIndex > dotLast - DOTS_FROM_END) {
        // console.log("Right, Near end, Locking transform");
        setDotTransform((dotLast - MAX_DOTS_TO_SHOW) * transformMultiplier);
        setDotVisibleRegionStart(dotLast - MAX_DOTS_TO_SHOW);
        setDotVisibleRegionEnd(dotLast);
      } else if (activeIndex > dotVisibleRegionEnd - DOTS_FROM_END) {
        // console.log("Right, somewhere in the middle, hugging the edge of the Transform");
        setDotTransform(
          (activeIndex - (MAX_DOTS_TO_SHOW - DOTS_FROM_END)) *
            transformMultiplier,
        );
        setDotVisibleRegionStart(
          activeIndex + DOTS_FROM_END - MAX_DOTS_TO_SHOW,
        );
        setDotVisibleRegionEnd(activeIndex + DOTS_FROM_END);
      } else if (activeIndex >= dotLast) {
        // console.log("Left, Looping Around");
        setDotTransform((dotLast - MAX_DOTS_TO_SHOW) * transformMultiplier);
        setDotVisibleRegionStart(dotLast - MAX_DOTS_TO_SHOW);
        setDotVisibleRegionEnd(dotLast);
      } else if (activeIndex < dotFirst + DOTS_FROM_END) {
        //console.log("Left, Near start, Locking transform");
        setDotTransform(dotFirst);
        setDotVisibleRegionStart(dotFirst);
        setDotVisibleRegionEnd(MAX_DOTS_TO_SHOW);
      } else if (activeIndex < dotVisibleRegionStart + DOTS_FROM_END) {
        // console.log("Left, somewhere in the middle, hugging the edge of the Transform");
        setDotTransform((activeIndex - DOTS_FROM_END) * transformMultiplier);
        setDotVisibleRegionStart(activeIndex - DOTS_FROM_END);
        setDotVisibleRegionEnd(activeIndex - DOTS_FROM_END + MAX_DOTS_TO_SHOW);
      } else {
        // console.log("somewhere in the middle, no need to Transform");
      }
    } else {
      // console.log("no transform");
    }
  }, [
    activeIndex,
    dotLast,
    dotVisibleRegionEnd,
    hasDotTransform,
    dotVisibleRegionStart,
    transformMultiplier,
  ]);

  return (
    <div className={combineClassNames(className, cx("dots-wrap"))}>
      {/* MAX_DOTS_TO_SHOW is 0 indexed, so +1 for the correct number of dots */}
      <div
        className={cx("dots-overflow")}
        style={{
          "--js-dots-width": `${
            (MAX_DOTS_TO_SHOW + 1) * DOT_SIZE_PX + MAX_DOTS_TO_SHOW * DOT_GAP_PX
          }px`,
        }}
      >
        <div
          className={cx("dots")}
          role="group"
          aria-label={template(
            translations["carousel.controls.controlsAriaLabel"],
            "Carousel controls",
          )}
          style={{
            "--js-dots-transform": hasDotTransform
              ? `${dotTransform}`
              : undefined,
          }}
        >
          {new Array(dotsCount).fill(undefined).map((card, index) => (
            <button
              key={index}
              aria-current={activeIndex === index}
              /* Leaving this here, as an alternative to aria-current */
              // aria-disabled={
              //     !cardStackState.isAnimating
              //       ? index < dotVisibleRegionStart ||
              //         index > dotVisibleRegionEnd
              //       : true
              //   }
              onClick={() => onClickDot(index)}
              className={cx("dot", {
                "dot--is-active": activeIndex === index,
                "dot--is-prev": hasDotTransform
                  ? index <= dotVisibleRegionStart
                  : undefined,
                "dot--is-next": hasDotTransform
                  ? index >= dotVisibleRegionEnd
                  : undefined,
                "dot--is-visible": hasDotTransform
                  ? index >= dotVisibleRegionStart &&
                    index <= dotVisibleRegionEnd
                  : undefined,
              })}
            >
              <span className={cx("dot-label")}>{dotLabelRenderFn(index)}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
